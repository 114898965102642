import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllQrs as getAllQrsApi } from "services/apis/admin";

export const fetchAllQrs = createAsyncThunk(
  "qrcode/fetchAll",
  async ({ token, currentPage, userType, qrStatus }: any, thunkAPI) => {
    try {
      const response = await getAllQrsApi(token, currentPage, userType, qrStatus);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface GetAllQrsState {
  qrs: any[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: GetAllQrsState = {
  qrs: [],
  status: "idle",
  error: null,
};

const getAllQrsSlice = createSlice({
  name: "getAllQrs",
  initialState,
  reducers: {
    resetGetAllQrsStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllQrs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllQrs.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.qrs = action.payload;
      })
      .addCase(fetchAllQrs.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetGetAllQrsStatus } = getAllQrsSlice.actions;
export default getAllQrsSlice.reducer;
