import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getPointHistory as getPointHistoryApi } from "services/apis/auth";

export const fetchPointHistory = createAsyncThunk(
  "point/fetchHistory",
  async ({ token, selectedTab, currentPage }: any, thunkAPI) => {
    try {
      const response = await getPointHistoryApi(token, selectedTab, currentPage);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface PointHistoryState {
  pointHistory: any[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: PointHistoryState = {
  pointHistory: [],
  status: "idle",
  error: null,
};

const getPointHistorySlice = createSlice({
  name: "getPointHistory",
  initialState,
  reducers: {
    resetPointHistoryStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPointHistory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPointHistory.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.pointHistory = action.payload;
      })
      .addCase(fetchPointHistory.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetPointHistoryStatus } = getPointHistorySlice.actions;
export default getPointHistorySlice.reducer;
