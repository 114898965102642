import { combineReducers } from "redux";
import loginSlice from "../slices/auth/loginSlice";
import adminLoginSlice from "../slices/admin/adminLoginUser";
import restrictUserSlice from "store/slices/admin/restrictUserSlice";
import allUsersSlice from "store/slices/admin/allUsersSlice";
import activateUserSlice from "store/slices/admin/activateUserSlice";
import deleteUserSlice from "store/slices/admin/deleteUserSlice";
import userDetailByIdSlice from "store/slices/admin/userDetailByIdSlice";
import getPromotionsSlice from "store/slices/admin/getPromotionsSlice";
import updatePromotionSlice from "store/slices/admin/updatePromotionSlice";
import createPromotionSlice from "store/slices/admin/createPromotionSlice";
import generateQrImageSlice from "store/slices/admin/generateQrImageSlice";
import getAllQrsSlice from "store/slices/admin/getAllQrsSlice";
import signupSlice from "store/slices/auth/signupSlice";
import scanQrSlice from "store/slices/user/scanQrSlice";
import getPointHistorySlice from "store/slices/user/getPointHistorySlice";
import redeemedRequestSlice from "store/slices/user/redeemedRequestSlice";
import getTotalUnreadAlertsSlice from "store/slices/user/getTotalUnreadAlertsSlice";
import getUserNotificationsSlice from "store/slices/user/getUserNotificationsSlice";
import verifyCodeSlice from "store/slices/auth/verifyCodeSlice";
import getPendingRedeemVerificationsSlice from "store/slices/admin/getPendingRedeemVerificationsSlice";
import approveRedeemVerificationsSlice from "store/slices/admin/approveRedeemVerificationsSlice";
import downloadQrSlice from "store/slices/admin/downloadQrSlice";
import getUserInfoSlice from "store/slices/user/getUserInfoSlice";
import downloadQrsByIdSlice from "store/slices/admin/downloadQrsByIdSlice";
import sendVerificationEmailForForgotPassSlice from "store/slices/auth/sendVerificationEmailForForgotPassSlice";
import verifyCodeForForgetPassSlice from "store/slices/auth/verifyCodeForForgetPassSlice";
import changePasswordAfterEmailVerificationSlice from "store/slices/auth/changePasswordAfterEmailVerificationSlice";
import changePasswordSlice from "store/slices/user/changePasswordSlice";
import adjustPointsSlice from "store/slices/admin/adjustPointsSlice";
import updateQrSlice from "store/slices/admin/updateQrSlice";
import updateProfileSlice from "store/slices/user/updateProfileSlice";
import rejectRedeemVerificationsSlice from "store/slices/admin/rejectRedeemVerificationsSlice";
import createBrandingImageSlice from "store/slices/admin/createBrandingImageSlice";
import getBrandingImageSlice from "store/slices/admin/getBrandingImageSlice";
import deletePromotionSlice from "store/slices/admin/deletePromotionSlice";
const rootReducer = combineReducers({
  // Accounts
  login: loginSlice,
  signup: signupSlice,
  verifyCode: verifyCodeSlice,
  sendVerificationEmailForForgotPass: sendVerificationEmailForForgotPassSlice,
  verifyCodeForForgetPass: verifyCodeForForgetPassSlice,
  changePasswordAfterEmailVerification: changePasswordAfterEmailVerificationSlice,
  // Admin
  adminLogin: adminLoginSlice,
  allUsers: allUsersSlice,
  restrictUser: restrictUserSlice,
  activateUser: activateUserSlice,
  deleteUser: deleteUserSlice,
  userDetailById: userDetailByIdSlice,
  getPromotions: getPromotionsSlice,
  createPromotion: createPromotionSlice,
  updatePromotion: updatePromotionSlice,
  generateQrImage: generateQrImageSlice,
  getAllQrs: getAllQrsSlice,
  getPendingRedeemVerifications: getPendingRedeemVerificationsSlice,
  approveRedeemVerifications: approveRedeemVerificationsSlice,
  rejectRedeemVerifications: rejectRedeemVerificationsSlice,
  downloadQr: downloadQrSlice,
  downloadQrsById: downloadQrsByIdSlice,
  adjustPoints: adjustPointsSlice,
  updateQr: updateQrSlice,
  createBrandingImage: createBrandingImageSlice,
  getBrandingImage: getBrandingImageSlice,
  deletePromotion: deletePromotionSlice,
  // User
  scanQr: scanQrSlice,
  getPointHistory: getPointHistorySlice,
  redeemedRequest: redeemedRequestSlice,
  getTotalUnreadAlerts: getTotalUnreadAlertsSlice,
  getUserNotifications: getUserNotificationsSlice,
  getUserInfo: getUserInfoSlice,
  changePassword: changePasswordSlice,
  // Profile
  updateProfile: updateProfileSlice,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
