import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUserInfo as getUserInfoApi } from "services/apis/auth"; 

export const fetchUserInfo = createAsyncThunk(
  "user/fetchUserInfo",
  async (token: string, thunkAPI) => {
    try {
      const response = await getUserInfoApi(token);
      return response.data;
    } catch (error:any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface UserInfoState {
  userInfo: any; 
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: UserInfoState = {
  userInfo: null,
  status: "idle",
  error: null,
};

const getUserInfoSlice = createSlice({
  name: "getUserInfo",
  initialState,
  reducers: {
    resetUserInfoStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserInfo.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserInfo.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.userInfo = action.payload;
      })
      .addCase(fetchUserInfo.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetUserInfoStatus } = getUserInfoSlice.actions;
export default getUserInfoSlice.reducer;
