import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { sendVerificationEmailForForgotPass as sendVerificationEmailForForgotPassApi } from "services/apis/auth";

export const sendVerificationEmailForForgotPass = createAsyncThunk(
  "auth/sendVerificationEmailForForgotPass",
  async (email: string, thunkAPI) => {
    try {
      const formData = new FormData();
      formData.append("email", email);
      const response = await sendVerificationEmailForForgotPassApi(formData);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface SendVerificationEmailForForgotPassState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: SendVerificationEmailForForgotPassState = {
  status: "idle",
  error: null,
};

const sendVerificationEmailForForgotPassSlice = createSlice({
  name: "sendVerificationEmailForForgotPass",
  initialState,
  reducers: {
    resetSendVerificationEmailForForgotPassStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendVerificationEmailForForgotPass.pending, (state) => {
        state.status = "loading";
      })
      .addCase(sendVerificationEmailForForgotPass.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(sendVerificationEmailForForgotPass.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetSendVerificationEmailForForgotPassStatus } = sendVerificationEmailForForgotPassSlice.actions;
export default sendVerificationEmailForForgotPassSlice.reducer;
