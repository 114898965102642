import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { deleteUserAccount } from "services/apis/admin";

export const deleteUser = createAsyncThunk(
  "user/delete",
  async ({ userId, token }: { userId: any; token: string }, thunkAPI) => {
    try {
      const formData = new FormData();
      formData.append("user_id", userId);
      const { data } = await deleteUserAccount(token, formData);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface DeleteUserState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: DeleteUserState = {
  status: "idle",
  error: null,
};

const deleteUserSlice = createSlice({
  name: "deleteUser",
  initialState,
  reducers: {
    resetDeleteUserStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteUser.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(deleteUser.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});
export const { resetDeleteUserStatus } = deleteUserSlice.actions;
export default deleteUserSlice.reducer;
