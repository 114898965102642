import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUserDetailsById } from "services/apis/admin";

export const fetchUserDetailsById = createAsyncThunk(
  "user/fetchDetailsById",
  async ({ userId, token }: { userId: any; token: string }, thunkAPI) => {
    try {
      const { data } = await getUserDetailsById(token, userId);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface UserDetailByIdState {
  userDetails: any;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: UserDetailByIdState = {
  userDetails: {},
  status: "idle",
  error: null,
};

const userDetailByIdSlice = createSlice({
  name: "userDetailById",
  initialState,
  reducers: {
    resetUserDetailStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserDetailsById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserDetailsById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.userDetails = action.payload;
      })
      .addCase(fetchUserDetailsById.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});
export const { resetUserDetailStatus } = userDetailByIdSlice.actions;
export default userDetailByIdSlice.reducer;
