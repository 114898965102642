import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { updatePromotion } from "services/apis/admin";

export const modifyPromotion = createAsyncThunk(
  "promotion/modify",
  async ({ promotionId, promotionData, token }: any, thunkAPI) => {
    try {
      const formData = new FormData();
      for (const [key, value] of Object.entries(promotionData)) {
        if (value !== undefined && value !== null) {
          if (typeof value === "object" && !(value instanceof Blob)) {
            // If the value is an object and not a Blob, convert it to a JSON string
            formData.append(key, JSON.stringify(value));
          } else {
            // Explicitly define the types for the value variable
            formData.append(key, value as string | Blob);
          }
        }
      }
      const { data } = await updatePromotion(token, formData, promotionId);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface UpdatePromotionState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: UpdatePromotionState = {
  status: "idle",
  error: null,
};

const updatePromotionSlice = createSlice({
  name: "updatePromotion",
  initialState,
  reducers: {
    resetUpdatePromotionStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(modifyPromotion.pending, (state) => {
        state.status = "loading";
      })
      .addCase(modifyPromotion.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(modifyPromotion.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetUpdatePromotionStatus } = updatePromotionSlice.actions;
export default updatePromotionSlice.reducer;
