import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { verifyCodeForForgetPass as verifyCodeForForgetPassApi } from "services/apis/auth";

export const verifyCodeForForgetPass = createAsyncThunk(
  "auth/verifyCodeForForgetPass",
  async ({ email, code, for_password }: any, thunkAPI) => {
    try {
      const formData = new FormData();
      formData.append("email", email);
      formData.append("code", code);
      formData.append("for_password", for_password);
      const response = await verifyCodeForForgetPassApi(formData);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface VerifyCodeForForgetPassState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: VerifyCodeForForgetPassState = {
  status: "idle",
  error: null,
};

const verifyCodeForForgetPassSlice = createSlice({
  name: "verifyCodeForForgetPass",
  initialState,
  reducers: {
    resetVerifyCodeForForgetPassStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyCodeForForgetPass.pending, (state) => {
        state.status = "loading";
      })
      .addCase(verifyCodeForForgetPass.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(verifyCodeForForgetPass.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetVerifyCodeForForgetPassStatus } = verifyCodeForForgetPassSlice.actions;
export default verifyCodeForForgetPassSlice.reducer;
