import axios from "axios";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_Base_URL}`,
});

const config = (token: any) => {
  return {
    headers: {
      Authorization: `Bearer ` + token,
    },
  };
};

export const signup = (formData: any) => api.post("/accounts/register/", formData);

export const login = (formData: any) => api.post("/accounts/login/", formData);

export const verifyCode = (formData: any) => api.post("/accounts/verify-code/", formData);

export const resendCodeForEmailVerification = (formData: any) =>
  api.post("/accounts/send-verification-email/", formData);

export const sendVerificationEmailForForgotPass = (formData: any) =>
  api.post(`/accounts/send-verification-email-password/`, formData);

export const changePasswordAfterEmailVerification = (formData: any) =>
  api.post(`/accounts/change-forgot-password/`, formData);

export const verifyCodeForForgetPass = (formData: any) => api.post("/accounts/verify-code/", formData);

export const changePassAfterEmailVerify = (formData: any) => api.post("/accounts/change-password/", formData);

export const scanQrCode = (formData: any, token: any) => api.post("/manage-qr/scan-qr/", formData, config(token));

export const getPointHistory = (token: any, selectedTab: any, currentPage: any) => {
  let url = `/manage-qr/user-point-history?page=${currentPage}`;
  if (selectedTab !== "") {
    url += `&${selectedTab}=true`;
  }
  return api.get(url, config(token));
};

export const redeemRequest = (token: any, formData: any) => {
  let url = `/manage-qr/make-redeem-request/`;
  return api.post(url, formData, config(token));
};

export const getTotalUnreadAlerts = (token: any) => {
  let url = `/alerts/get-total-unread/`;
  return api.get(url, config(token));
};

export const getUserNotifications = (token: any, currentPage: any) => {
  let url = `/alerts/notifications/?page=${currentPage}`;
  return api.get(url, config(token));
};

export const getUserInfo = (token: any) => {
  let url = `/accounts/profile/`;
  return api.get(url, config(token));
};

export const changePassword = (formData: any, token: any) =>
  api.post("/accounts/change-password/", formData, config(token));

export const updateProfile = (formData: any, token: any) =>
  api.put("/accounts/update-profile/", formData, config(token));
