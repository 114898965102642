import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getTotalUnreadAlerts as getTotalUnreadAlertsApi } from "services/apis/auth";

export const fetchTotalUnreadAlerts = createAsyncThunk("alert/fetchTotalUnread", async (token: string, thunkAPI) => {
  try {
    const response = await getTotalUnreadAlertsApi(token);
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

interface TotalUnreadAlertsState {
  totalUnreadAlerts: any;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: TotalUnreadAlertsState = {
  totalUnreadAlerts: 0,
  status: "idle",
  error: null,
};

const getTotalUnreadAlertsSlice = createSlice({
  name: "getTotalUnreadAlerts",
  initialState,
  reducers: {
    resetTotalUnreadAlertsStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTotalUnreadAlerts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTotalUnreadAlerts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.totalUnreadAlerts = action.payload;
      })
      .addCase(fetchTotalUnreadAlerts.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetTotalUnreadAlertsStatus } = getTotalUnreadAlertsSlice.actions;
export default getTotalUnreadAlertsSlice.reducer;
