import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { updateProfile as updateProfileApi } from "services/apis/auth";

export const updateProfile = createAsyncThunk(
  "profile/updateProfile",
  async ({ token, profileData }: any, thunkAPI) => {
    try {
      const formData = new FormData();
      for (const key in profileData) {
        formData.append(key, profileData[key]);
      }
      if (profileData.img_id) {
        formData.append("profile_img", profileData.img_id);
      }
      const response = await updateProfileApi(formData, token);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

const initialState = {
  status: "idle",
  error: null,
  profile: null,
};

const updateProfileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    resetProfileStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateProfile.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.profile = action.payload;
      })
      .addCase(updateProfile.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetProfileStatus } = updateProfileSlice.actions;
export default updateProfileSlice.reducer;
