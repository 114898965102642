import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { rejectRedeemVerification as rejectRedeemVerificationApi } from "services/apis/admin";

export const rejectRedeemVerification = createAsyncThunk(
  "redeemVerification/reject",
  async ({ request_id, token }: any, thunkAPI) => {
    try {
      const formData = new FormData();
      formData.append("request_id", request_id);
      const response = await rejectRedeemVerificationApi(formData, token);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface ApproveRedeemVerificationsState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: ApproveRedeemVerificationsState = {
  status: "idle",
  error: null,
};

const rejectRedeemVerificationsSlice = createSlice({
  name: "rejectRedeemVerifications",
  initialState,
  reducers: {
    resetRejectRedeemVerificationsStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(rejectRedeemVerification.pending, (state) => {
        state.status = "loading";
      })
      .addCase(rejectRedeemVerification.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(rejectRedeemVerification.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetRejectRedeemVerificationsStatus } = rejectRedeemVerificationsSlice.actions;
export default rejectRedeemVerificationsSlice.reducer;
