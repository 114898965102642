import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { generateQrImage as generateQrImageApi } from "services/apis/admin";
export const generateQrImage = createAsyncThunk("qrcode/generate", async ({ token, data }: any, thunkAPI) => {
  try {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    const response = await generateQrImageApi(token, formData);
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

interface GenerateQrImageState {
  imageUrl: string | null;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: GenerateQrImageState = {
  imageUrl: null,
  status: "idle",
  error: null,
};

const generateQrImageSlice = createSlice({
  name: "generateQrImage",
  initialState,
  reducers: {
    resetGenerateQrImageStatus: (state) => {
      state.status = "idle";
      state.error = null;
      state.imageUrl = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(generateQrImage.pending, (state) => {
        state.status = "loading";
      })
      .addCase(generateQrImage.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.imageUrl = action.payload;
      })
      .addCase(generateQrImage.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetGenerateQrImageStatus } = generateQrImageSlice.actions;
export default generateQrImageSlice.reducer;
