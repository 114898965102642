import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { adjustPoints as adjustPointsApi } from "services/apis/admin";

export const adjustPoints = createAsyncThunk(
  "points/adjustPoints",
  async ({ adjustmentData, token }: any, thunkAPI) => {
    try {
      const formData = new FormData();
      for (const key in adjustmentData) {
        formData.append(key, adjustmentData[key]);
      }
      const response = await adjustPointsApi(token, formData);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface AdjustPointsState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: AdjustPointsState = {
  status: "idle",
  error: null,
};

const adjustPointsSlice = createSlice({
  name: "adjustPoints",
  initialState,
  reducers: {
    resetAdjustPointsStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(adjustPoints.pending, (state) => {
        state.status = "loading";
      })
      .addCase(adjustPoints.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(adjustPoints.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetAdjustPointsStatus } = adjustPointsSlice.actions;
export default adjustPointsSlice.reducer;
