import { configureStore } from "@reduxjs/toolkit";
import rootReducer, { RootState } from "./rootReducer/rootReducer";
import createFilter from "redux-persist-transform-filter";
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import storage from "redux-persist/lib/storage";
const devTools =
  process.env.REACT_APP_NODE_ENV === "development"
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()
    : undefined;

const loginFilter = createFilter("login", ["isLoggedIn", "user"]);
const adminLoginFilter = createFilter("adminLogin", ["isLoggedIn", "admin"]);
// const signupFilter = createFilter("signup", ["user.detail.email", "user.detail.id", "date"]);
const persistConfig = {
  key: "skzic-rewards-app",
  storage,
  whitelist: ["login", "signup", "generateStories", "activeProfiles", "adminLogin"],
  transforms: [
    loginFilter,
    adminLoginFilter,
    // signupFilter /* ctxFilter, selectedAccountFiler,, generateStoriesFilter*/,
  ],
};

const persistedReducer = persistReducer<RootState>(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

const persistor = persistStore(store);
export { store, persistor };
export type AppDispatch = typeof store.dispatch;
