import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useAppSelector } from "services/hooks/hooks";

const BottomNav = () => {
  const isLoggedIn = useAppSelector((state) => state.login.isLoggedIn);
  const { pathname } = useLocation();
  const data = [
    {
      imgUrl: require("assets/icons/bottomNav/Home.png"),
      activeImgUrl: require("assets/icons/bottomNav/homeActive.png"),
      name: "Home",
      active: "/",
    },
    {
      imgUrl: require("assets/icons/bottomNav/rewards.png"),
      activeImgUrl: require("assets/icons/bottomNav/rewardsActive.png"),
      name: "Rewards",
      active: "/#rewards",
    },
    {
      imgUrl: require("assets/icons/bottomNav/scanQr.png"),
      activeImgUrl: require("assets/icons/bottomNav/scanQrActive.png"),
      name: "Scan QR",
      active: "/qr-scanner",
    },
    {
      imgUrl: require("assets/icons/bottomNav/uploadQr.png"),
      activeImgUrl: require("assets/icons/bottomNav/uploadQrActive.png"),
      name: "Upload QR",
      active: "/scan-qr",
    },
    {
      imgUrl: require("assets/icons/bottomNav/profile.png"),
      activeImgUrl: require("assets/icons/bottomNav/profileActive.png"),
      name: "Profile",
      active: "/profile",
    },
  ];
  return (
    <div
      className={`py-3 px-8 bg-[#007074] lg:hidden block fixed left-0 bottom-0 w-full ${
        isLoggedIn ? "block" : "hidden"
      }`}
      style={{ zIndex: 9 }}
    >
      <div className="w-full h-full flex justify-center items-center gap-5 md:gap-10 lg:gap-5">
        {data.map((val, index) => {
          return (
            <Link
              to={val.active}
              key={index}
              className={`${pathname === val.active ? "opacity-[1]" : "opacity-[0.2]"}`}
            >
              <img src={pathname === val.active ? val.activeImgUrl : val.imgUrl} alt="" className="w-6 h-6 mx-auto" />
              <p className={`font-normal text-xs ${pathname === val.active ? "text-white" : "text-black"} `}>
                {val.name}
              </p>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default BottomNav;
