import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUserNotifications as getUserNotificationsApi } from "services/apis/auth";

export const fetchUserNotifications = createAsyncThunk(
  "notification/fetchUserNotifications",
  async ({ token, currentPage }: any, thunkAPI) => {
    try {
      const response = await getUserNotificationsApi(token, currentPage);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface UserNotificationsState {
  userNotifications: any;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: UserNotificationsState = {
  userNotifications: [],
  status: "idle",
  error: null,
};

const getUserNotificationsSlice = createSlice({
  name: "getUserNotifications",
  initialState,
  reducers: {
    resetUserNotificationsStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserNotifications.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserNotifications.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.userNotifications = action.payload;
      })
      .addCase(fetchUserNotifications.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetUserNotificationsStatus } = getUserNotificationsSlice.actions;
export default getUserNotificationsSlice.reducer;
