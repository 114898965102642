import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createBrandingImage as createBrandingImageApi } from "services/apis/admin";

export const createBrandingImage = createAsyncThunk(
  "brandingImage/createBrandingImage",
  async ({ brandingImageData, token }: any, thunkAPI) => {
    try {
      const formData = new FormData();
      formData.append("youtube_urls", brandingImageData);
      const response = await createBrandingImageApi(token, formData);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface CreateBrandingImageState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: CreateBrandingImageState = {
  status: "idle",
  error: null,
};

const createBrandingImageSlice = createSlice({
  name: "createBrandingImage",
  initialState,
  reducers: {
    resetCreateBrandingImageStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createBrandingImage.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createBrandingImage.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(createBrandingImage.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetCreateBrandingImageStatus } = createBrandingImageSlice.actions;
export default createBrandingImageSlice.reducer;
