import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { scanQrCode as scanQrCodeApi } from "services/apis/auth";

export const scanQrCode = createAsyncThunk(
  "qrcode/scan",
  async ({ data, token, country, state, latitude, longitude }: any, thunkAPI) => {
    try {
      const formData = new FormData();
      formData.append("qr", data);
      formData.append("country", country);
      formData.append("state", state);
      formData.append("location", "");
      formData.append("latitude", latitude);
      formData.append("longitude", longitude);
      const response = await scanQrCodeApi(formData, token);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface ScanQrState {
  scannedData: any;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: ScanQrState = {
  scannedData: null,
  status: "idle",
  error: null,
};

const scanQrSlice = createSlice({
  name: "scanQr",
  initialState,
  reducers: {
    resetScanQrStatus: (state) => {
      state.status = "idle";
      state.error = null;
      // state.scannedData = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(scanQrCode.pending, (state) => {
        state.status = "loading";
      })
      .addCase(scanQrCode.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.scannedData = action.payload;
      })
      .addCase(scanQrCode.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetScanQrStatus } = scanQrSlice.actions;
export default scanQrSlice.reducer;
