import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { changePassword as changePasswordApi } from "services/apis/auth";

export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async ({ old_password, password1, password2,token }: any, thunkAPI) => {
    try {
      const formData = new FormData();
      formData.append("old_password", old_password);
      formData.append("password1", password1);
      formData.append("password2", password2);
      const response = await changePasswordApi(formData,token);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface ChangePasswordState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: ChangePasswordState = {
  status: "idle",
  error: null,
};

const changePasswordSlice = createSlice({
  name: "changePassword",
  initialState,
  reducers: {
    resetChangePasswordStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(changePassword.pending, (state) => {
        state.status = "loading";
      })
      .addCase(changePassword.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(changePassword.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetChangePasswordStatus } = changePasswordSlice.actions;
export default changePasswordSlice.reducer;
