import React from "react";
import { notification } from "antd";
import { errorToastProps } from "services/interfaces/toasts";

type NotificationType = "success";
const config = {
  maxCount: 1,
};

const Success: React.FC<errorToastProps> = ({ message, description }) => {
  const [api, contextHolder] = notification.useNotification(config);

  const openNotificationWithIcon = (type: NotificationType) => {
    api[type]({
      message: message,
      description: description,
      // duration: 100000,
      className: "custom-toast-success",
    });
  };
  React.useEffect(() => {
    openNotificationWithIcon("success");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <>{contextHolder}</>;
};

export default Success;
