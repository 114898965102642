import React from "react";

const CurrentlyOffline = ({ offline, setOffline }: any) => {
  return (
    <>
      {offline ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-[rgba(0,0,0,0.75)] transition-opacity duration-300">
            <div className="relative w-[90%] mx-auto h-[80vh]">
              {/*content*/}
              <div className="px-[20px] border-0 rounded-3xl shadow-lg relative flex flex-col w-full bg-[linear-gradient(135deg,#AEEAEA_0%,#EEE6DD_100%)] outline-none focus:outline-none h-full">
                <button
                  className="p-2 flex justify-center items-center absolute right-4 top-4 cursor-pointer z-50"
                  onClick={() => {
                    setOffline(false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                      fill="black"
                    />
                  </svg>
                </button>
                <div className="relative flex-auto flex justify-center items-center">
                  <div className="max-w-[811px] mx-auto text-center">
                    <div>
                      <img
                        src={require("assets/icons/3d-buddy-white-no-wifi-icon-turned-to-the-right 1.png")}
                        alt=""
                        className="w-[148px] h-[160px] mx-auto mb-2.5"
                      />
                      <h1 className="text-[#005053] font-publicsans text-6xl font-medium text-center">
                        You are Currently Offline!
                      </h1>

                      <h6 className="text-black/[0.4] font-publicsans text-3xl font-semibold text-center my-2.5">
                        Please check your internet connection or try again later
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default CurrentlyOffline;
