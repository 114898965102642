import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { updateQr as updateQrApi } from "services/apis/admin";

export const updateQr = createAsyncThunk("qr/updateQr", async ({ qrData, token }: any, thunkAPI) => {
  try {
    const response = await updateQrApi(token, qrData);
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

interface UpdateQrState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: UpdateQrState = {
  status: "idle",
  error: null,
};

const updateQrSlice = createSlice({
  name: "updateQr",
  initialState,
  reducers: {
    resetUpdateQrStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateQr.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateQr.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(updateQr.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetUpdateQrStatus } = updateQrSlice.actions;
export default updateQrSlice.reducer;
