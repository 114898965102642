import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { verifyCode as verifyCodeApi } from "services/apis/auth";

export const verifyCode = createAsyncThunk("code/verify", async ({ code, email }: any, thunkAPI) => {
  try {
    const formData = new FormData();
    formData.append("code", code);
    formData.append("email", email);
    const response = await verifyCodeApi(formData);
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

interface VerifyCodeState {
  isValid: boolean;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: VerifyCodeState = {
  isValid: false,
  status: "idle",
  error: null,
};

const verifyCodeSlice = createSlice({
  name: "verifyCode",
  reducers: {
    resetVerifyCodeStatus: (state) => {
      state.status = "idle";
      state.error = null;
      state.isValid = false;
    },
  },
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(verifyCode.pending, (state) => {
        state.status = "loading";
      })
      .addCase(verifyCode.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.isValid = action.payload;
      })
      .addCase(verifyCode.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetVerifyCodeStatus } = verifyCodeSlice.actions;
export default verifyCodeSlice.reducer;
