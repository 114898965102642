import React from "react";
import "assets/styles/preloader.scss";
const Preloader = () => {
  return (
    <div className="wrapperWrapper">
      <div className="wrapper">
        <svg className="pl2" viewBox="0 0 128 128" width="128px" height="128px">
          <g fill="#FF5C34">
            <g className="pl2__rect-g">
              <rect className="pl2__rect" rx="8" ry="8" x="0" y="128" width="40" height="24" transform="rotate(180)" />
            </g>
            <g className="pl2__rect-g">
              <rect className="pl2__rect" rx="8" ry="8" x="44" y="128" width="40" height="24" transform="rotate(180)" />
            </g>
            <g className="pl2__rect-g">
              <rect className="pl2__rect" rx="8" ry="8" x="88" y="128" width="40" height="24" transform="rotate(180)" />
            </g>
          </g>
          <g fill="#FF5C34" mask="url(#pl-mask)">
            <g className="pl2__rect-g">
              <rect className="pl2__rect" rx="8" ry="8" x="0" y="128" width="40" height="24" transform="rotate(180)" />
            </g>
            <g className="pl2__rect-g">
              <rect className="pl2__rect" rx="8" ry="8" x="44" y="128" width="40" height="24" transform="rotate(180)" />
            </g>
            <g className="pl2__rect-g">
              <rect className="pl2__rect" rx="8" ry="8" x="88" y="128" width="40" height="24" transform="rotate(180)" />
            </g>
          </g>
        </svg>
      </div>
    </div>
  );
};

export default Preloader;
