// import React, { lazy, Suspense } from "react";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import PreLoader from "components/preloader/Preloader";
// import CurrentlyOffline from "components/Modals/CurrentlyOffline";
// import Success from "components/toasts/alerts/Success";
// import { useAppSelector } from "services/hooks/hooks";
// import Info from "components/toasts/alerts/Info";
// import Warning from "components/toasts/alerts/Warning";
// import Error from "components/toasts/alerts/Error";
// import BottomNav from "components/common/BottomNav";
// const Home = lazy(() => import("pages/Home"));
// const About = lazy(() => import("pages/About"));
// const Login = lazy(() => import("pages/Login"));
// const Signup = lazy(() => import("pages/Signup"));
// const SignupSuccess = lazy(() => import("pages/SignupSuccess"));
// const PointHistory = lazy(() => import("pages/PointHistory"));
// const ScanQr = lazy(() => import("pages/ScanQr"));
// const QrScanner = lazy(() => import("pages/QrScanner"));
// const ScannedQrDetails = lazy(() => import("pages/ScannedQrDetails"));
// const Profile = lazy(() => import("pages/Profile"));
// const AdminLogin = lazy(() => import("pages/admin/AdminLogin"));
// const Dashboard = lazy(() => import("pages/admin/Dashboard"));
// const ManageUser = lazy(() => import("pages/admin/ManageUser"));
// const ManageUserDetail = lazy(() => import("pages/admin/ManageUserDetail"));
// const FrontlineQr = lazy(() => import("pages/admin/FrontlineQr"));
// const ManagePromotions = lazy(() => import("pages/admin/ManagePromotions"));
// const CreatePromotion = lazy(() => import("pages/admin/CreatePromotion"));
// const QrList = lazy(() => import("pages/admin/QrList"));
// const RtoOwnerQr = lazy(() => import("pages/admin/RtoOwnerQr"));
// const Rewards = lazy(() => import("pages/admin/Rewards"));
// const HelpAndSupport = lazy(() => import("pages/HelpAndSupport"));
// const DownloadQr = lazy(() => import("pages/admin/DownloadQr"));
// const UpdateQr = lazy(() => import("pages/admin/UpdateQr"));
// const CMS = lazy(() => import("pages/admin/CMS"));

// const Config = () => {
//   const [offline, setOffline] = React.useState(!navigator.onLine);
//   const adminLoginReducer = useAppSelector((state) => state.adminLogin.status);
//   const user_id = useAppSelector((state) => state?.login?.user?.id);
//   const admin_id = useAppSelector((state) => state?.adminLogin?.admin?.id);
//   const [socket, setSocket] = React.useState<any>(null);
//   const [incomingMessage, setIncomingMessage] = React.useState<any>(null);
//   // console.log("incomingMessage::: ", incomingMessage);

//   React.useEffect(() => {
//     if (incomingMessage) {
//       const timer = setTimeout(() => {
//         setIncomingMessage(null); // Clear the incomingMessage state after 3 seconds
//       }, 3000);

//       return () => clearTimeout(timer);
//     }
//   }, [incomingMessage]);

//   React.useEffect(() => {
//     const webSocketEndpoint = `wss://api-dev.skzicph.com/ws/alerts?user_id=${user_id ? user_id : admin_id}`;

//     const newSocket = new WebSocket(webSocketEndpoint);

//     newSocket.onmessage = function (e) {
//       const messageData = JSON.parse(e.data);
//       setIncomingMessage(messageData);
//     };

//     newSocket.onopen = function (e) {
//       // console.log("open", e);
//     };

//     newSocket.onerror = function (e) {
//       // console.log("error", e);
//     };

//     newSocket.onclose = function (e) {
//       // console.log("closed", e);
//     };

//     setSocket(newSocket);

//     // Clean up the WebSocket connection when the component unmounts
//     return () => {
//       newSocket.close();
//     };
//   }, []);
//   return (
//     <Router>
//       <CurrentlyOffline offline={offline} setOffline={setOffline} />
//       {adminLoginReducer === "succeeded" && <Success message="Success" description="Login Successful" />}
//       {incomingMessage && (
//         <div>
//           {incomingMessage.notification_type === "success" && (
//             <Success message={incomingMessage.message} description={incomingMessage.timestamp} />
//           )}
//           {incomingMessage.notification_type === "info" && (
//             <Info message={incomingMessage.message} description={incomingMessage.timestamp} />
//           )}
//           {incomingMessage.notification_type === "warning" && (
//             <Warning message={incomingMessage.message} description={incomingMessage.timestamp} />
//           )}
//           {incomingMessage.notification_type === "error" && (
//             <Error message={incomingMessage.message} description={incomingMessage.timestamp} />
//           )}
//         </div>
//       )}
//       <Suspense fallback={<PreLoader />}>
//         <Routes>
//           <Route path="/" element={<Home />}></Route>
//           <Route path="/about-us" element={<About />}></Route>
//           <Route path="/login" element={<Login />}></Route>
//           <Route path="/signup" element={<Signup />}></Route>
//           <Route path="/signup-success" element={<SignupSuccess />}></Route>
//           <Route path="/point-history" element={<PointHistory />}></Route>
//           <Route path="/scan-qr" element={<ScanQr />}></Route>
//           <Route path="/qr-scanner" element={<QrScanner />}></Route>
//           <Route path="/help-and-support" element={<HelpAndSupport />}></Route>
//           <Route path="/app/qr-detail" element={<ScannedQrDetails />}></Route>
//           <Route path="/profile" element={<Profile />}></Route>
//           <Route path="/admin/login" element={<AdminLogin />}></Route>
//           <Route path="/admin/dashboard" element={<Dashboard />}></Route>
//           <Route path="/admin/manage-users" element={<ManageUser />}></Route>
//           <Route path="/admin/manage-users/:id?" element={<ManageUserDetail />}></Route>
//           <Route path="/admin/frontline-qr" element={<FrontlineQr />}></Route>
//           <Route path="/admin/rto-owner-qr" element={<RtoOwnerQr />}></Route>
//           <Route path="/admin/manage-promotions" element={<ManagePromotions />}></Route>
//           <Route path="/admin/create-promotions" element={<CreatePromotion />}></Route>
//           <Route path="/admin/qr-list" element={<QrList />}></Route>
//           <Route path="/admin/rewards" element={<Rewards />}></Route>
//           <Route path="/admin/download-qr" element={<DownloadQr />}></Route>
//           <Route path="/admin/cms" element={<CMS />}></Route>
//           <Route path="/admin/update-qr" element={<UpdateQr />}></Route>
//         </Routes>
//         <BottomNav />
//       </Suspense>
//     </Router>
//   );
// };

// export default Config;

import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import PreLoader from "components/preloader/Preloader";
import CurrentlyOffline from "components/Modals/CurrentlyOffline";
import Success from "components/toasts/alerts/Success";
import { useAppSelector } from "services/hooks/hooks";
import Info from "components/toasts/alerts/Info";
import Warning from "components/toasts/alerts/Warning";
import Error from "components/toasts/alerts/Error";
import BottomNav from "components/common/BottomNav";
const Home = lazy(() => import("pages/Home"));
const About = lazy(() => import("pages/About"));
const Login = lazy(() => import("pages/Login"));
const Signup = lazy(() => import("pages/Signup"));
const SignupSuccess = lazy(() => import("pages/SignupSuccess"));
const PointHistory = lazy(() => import("pages/PointHistory"));
const ScanQr = lazy(() => import("pages/ScanQr"));
const QrScanner = lazy(() => import("pages/QrScanner"));
const ScannedQrDetails = lazy(() => import("pages/ScannedQrDetails"));
const Profile = lazy(() => import("pages/Profile"));
const AdminLogin = lazy(() => import("pages/admin/AdminLogin"));
const Dashboard = lazy(() => import("pages/admin/Dashboard"));
const ManageUser = lazy(() => import("pages/admin/ManageUser"));
const ManageUserDetail = lazy(() => import("pages/admin/ManageUserDetail"));
const FrontlineQr = lazy(() => import("pages/admin/FrontlineQr"));
const ManagePromotions = lazy(() => import("pages/admin/ManagePromotions"));
const CreatePromotion = lazy(() => import("pages/admin/CreatePromotion"));
const QrList = lazy(() => import("pages/admin/QrList"));
const RtoOwnerQr = lazy(() => import("pages/admin/RtoOwnerQr"));
const Rewards = lazy(() => import("pages/admin/Rewards"));
const HelpAndSupport = lazy(() => import("pages/HelpAndSupport"));
const DownloadQr = lazy(() => import("pages/admin/DownloadQr"));
const UpdateQr = lazy(() => import("pages/admin/UpdateQr"));
const CMS = lazy(() => import("pages/admin/CMS"));

const Config = () => {
  const [offline, setOffline] = React.useState(!navigator.onLine);
  const adminLoginReducer = useAppSelector((state) => state.adminLogin.status);
  const user_id = useAppSelector((state) => state?.login?.user?.id);
  const admin_id = useAppSelector((state) => state?.adminLogin?.admin?.id);
  const [socket, setSocket] = React.useState<any>(null);
  const [incomingMessage, setIncomingMessage] = React.useState<any>(null);

  React.useEffect(() => {
    if (incomingMessage) {
      const timer = setTimeout(() => {
        setIncomingMessage(null); // Clear the incomingMessage state after 3 seconds
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [incomingMessage]);

  React.useEffect(() => {
    const webSocketEndpoint = `wss://api-dev.skzicph.com/ws/alerts?user_id=${user_id ? user_id : admin_id}`;

    const newSocket = new WebSocket(webSocketEndpoint);

    newSocket.onmessage = function (e) {
      const messageData = JSON.parse(e.data);
      setIncomingMessage(messageData);
    };

    newSocket.onopen = function (e) {
      // console.log("open", e);
    };

    newSocket.onerror = function (e) {
      // console.log("error", e);
    };

    newSocket.onclose = function (e) {
      // console.log("closed", e);
    };

    setSocket(newSocket);

    // Clean up the WebSocket connection when the component unmounts
    return () => {
      newSocket.close();
    };
  }, []);

  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith("/admin");

  return (
    <>
      <CurrentlyOffline offline={offline} setOffline={setOffline} />
      {adminLoginReducer === "succeeded" && <Success message="Success" description="Login Successful" />}
      {incomingMessage && (
        <div>
          {incomingMessage.notification_type === "success" && (
            <Success message={incomingMessage.message} description={incomingMessage.timestamp} />
          )}
          {incomingMessage.notification_type === "info" && (
            <Info message={incomingMessage.message} description={incomingMessage.timestamp} />
          )}
          {incomingMessage.notification_type === "warning" && (
            <Warning message={incomingMessage.message} description={incomingMessage.timestamp} />
          )}
          {incomingMessage.notification_type === "error" && (
            <Error message={incomingMessage.message} description={incomingMessage.timestamp} />
          )}
        </div>
      )}
      <Suspense fallback={<PreLoader />}>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/about-us" element={<About />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/signup" element={<Signup />}></Route>
          <Route path="/signup-success" element={<SignupSuccess />}></Route>
          <Route path="/point-history" element={<PointHistory />}></Route>
          <Route path="/scan-qr" element={<ScanQr />}></Route>
          <Route path="/qr-scanner" element={<QrScanner />}></Route>
          <Route path="/help-and-support" element={<HelpAndSupport />}></Route>
          <Route path="/app/qr-detail" element={<ScannedQrDetails />}></Route>
          <Route path="/profile" element={<Profile />}></Route>
          <Route path="/admin/login" element={<AdminLogin />}></Route>
          <Route path="/admin/dashboard" element={<Dashboard />}></Route>
          <Route path="/admin/manage-users" element={<ManageUser />}></Route>
          <Route path="/admin/manage-users/:id?" element={<ManageUserDetail />}></Route>
          <Route path="/admin/frontline-qr" element={<FrontlineQr />}></Route>
          <Route path="/admin/rto-owner-qr" element={<RtoOwnerQr />}></Route>
          <Route path="/admin/manage-promotions" element={<ManagePromotions />}></Route>
          <Route path="/admin/create-promotions" element={<CreatePromotion />}></Route>
          <Route path="/admin/qr-list" element={<QrList />}></Route>
          <Route path="/admin/rewards" element={<Rewards />}></Route>
          <Route path="/admin/download-qr" element={<DownloadQr />}></Route>
          <Route path="/admin/cms" element={<CMS />}></Route>
          <Route path="/admin/update-qr" element={<UpdateQr />}></Route>
        </Routes>
        {!isAdminRoute && <BottomNav />}
      </Suspense>
    </>
  );
};

const App = () => (
  <Router>
    <Config />
  </Router>
);

export default App;
