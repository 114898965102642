import React from "react";
import { notification } from "antd";
import { errorToastProps } from "services/interfaces/toasts";

type NotificationType = "info";
const config = {
  maxCount: 1,
};

const Info: React.FC<errorToastProps> = ({ message, description }) => {
  const [api, contextHolder] = notification.useNotification(config);

  const openNotificationWithIcon = (type: NotificationType) => {
    api[type]({
      message: message,
      description: description,
    //   duration: 100000,
      className: "custom-toast-success custom-toast-info",
    });
  };
  React.useEffect(() => {
    openNotificationWithIcon("info");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <>{contextHolder}</>;
};

export default Info;
